import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ServicesTitle from "../../components/ServicesTitle/ServicesTitle";
import ServicesInfo from "../../components/ServicesInfo/ServicesInfo";
import ServicesDetail from '../../components/ServicesDetail/ServicesDetail'

function Hizmetlerimiz() {
  return (
    <>
      <Header />
      <ServicesTitle />
      <ServicesInfo />
      <ServicesDetail />
      <Footer />
    </>
  );
}

export default Hizmetlerimiz;
