import React from 'react';
function AboutUsInfo() {
    return (
        <div className='AboutUsInfo'>
            <img className='AboutUsInfoImg' src='https://lirp.cdn-website.com/15c929da/dms3rep/multi/opt/serv2-1920w.jpg' alt='Hakkımızda Fotoğraf' />
            <div className='AboutUsInfoDescription'>
                <h2>
                    EntaOfis'le Rahat Edin
                </h2>
                <p>
                    Yılların tecrübesi ile birlikte kendimizi fotokopi ve baskı sektörünün önde gelen tedarikçilerinden biri olarak görmekteyiz. Ofis cihazlarını yalnızca onarım ve servis açısından değerlendirerek değil, aynı zamanda tekrarlanan sorunların önlenmesine yardımcı olabilecek önleyici bakım önlemlerini de uygulayarak uzun ömürlülüğü vaat etmekteyiz.
                </p>
                <p>
                    Uzun yıllardır bölgedeki yerel işletmelere olabildiğince eksiksiz çözümler sağlıyoruz. EntaOfis olarak her hizmetimizin arkasında gururla duruyoruz.
                </p>

            </div>
        </div>
    )
}

export default AboutUsInfo;
