import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { NavLink } from "react-router-dom";
import {Button} from "@chakra-ui/react"
function TonerContent() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Animasyonlu kaydırma için
    });
    
  };
  const opacity = useSpring({
    from: { opacity: 0, translateY: 30 },
    to: { opacity: 1, translateY: 0 },
  });
  const markalar = [
    {
      id: 1,
      aciklama: "Her türlü orjinal veya muadil toner tedariği yapmaktayız. Daha fazla bilgi için bizimle iletişime geçin!",
    },
  
  ];

  return (
    <div>
    <animated.div style={{ ...opacity }} className="tonerAni">
      <div className="tonerTitle">
        <h2 >Sarf Malzemeleri</h2>
      </div>
    </animated.div>
    <div className="markaKutu">
      {markalar.map((marka) => (
        <animated.div key={marka.id} style={{ ...opacity }} className="tonerAni">
          <div className="tonerContainer">
            <div className="tonerModel">
              {marka.aciklama && <p>{marka.aciklama}</p>}
            </div>
          </div>
        </animated.div>
      ))}
    </div>
    <NavLink onClick={scrollToTop} to="/iletisim"><Button size='lg' marginTop={["15", "-200"]} marginBottom="30" colorScheme='blue'>İletişim</Button> </NavLink>
  </div>
  );
}

export default TonerContent;
