import React from 'react'
import Header from '../../components/Header/Header'
import FotokopiContent from '../../components/FotokopiContent/FotokopiContent'
import Footer from  '../../components/Footer/Footer'
function Fotokopi() {
  return (
    <div className='Fotokopi'>
    <Header/>
    <FotokopiContent/>
    <Footer/>
      </div>
    
  )
}

export default Fotokopi