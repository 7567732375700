//import { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
function Promotion() {
  const opacity = useSpring({
    from: { opacity: 0, translateY: 30 },
    to: { opacity: 1, translateY: 0 },
  });
  /*const [scale, setScale] = useState(1);
  const handleScroll = () => {
    // Sayfanın alt kısmında bir kutu görüldüğünde kutu scale ini artıralım
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY;

    // Eğer sayfanın altına gelindiğinde
    if (scrollPosition + windowHeight >= documentHeight) {
      setScale((prevScale) => prevScale + 0.06);
    } else {
      setScale(1);
    }
  };
  useEffect(() => {
    // Scroll olayını dinleyelim
    window.addEventListener("scroll", handleScroll);

    // Temizlik yapalım (component kaldırıldığında scroll olayı dinlemeyi durduralım)
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); */
  return (

    <animated.div style={{ ...opacity }} className="promotion">
      <div
        className="promotionContent"
      >
        <p>
          Tüm büro makinesi ihtiyaçlarınızı karşılayacak, güvenilir bir şirkete mi ihtiyacınız var?
          EntaOfis'den başka bir yere bakmaya ihtiyacınız yok. Yıllardır profesyonel yazıcı, fotokopi makinesi ve her
          büyüklükteki işletme için ofis ekipmanı hizmetleri sunuyoruz.
          EntaOfis'i seçin, en iyisini seçmiş olursunuz!
        </p>
      </div>
    </animated.div>
  );
}
export default Promotion;
