import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import logo from "../../images/entaofis.png";
function Header() {
  const location = useLocation();
  const yukardanassa = useSpring({
    from: { y: -100 },
    to: { y: 0 },
  });
  const sagdansola = useSpring({
    from: { x: -1200 },
    to: { x: 0 },
  });
  const soldansaga = useSpring({
    from: { x: 1200 },
    to: { x: 0 },
  });

  return (
    <animated.div style={{ ...yukardanassa }} className="Header">
      <div className="lists">
        <div className="listOne">
          <ul>
            <Link to="/">
              <animated.li className={location.pathname === "/"  ? 'alti-uzun active' : ''}  style={{ ...sagdansola }}>Ana Sayfa</animated.li>
            </Link>
            <Link to="/hizmetlerimiz">
              <animated.li className={location.pathname === "/hizmetlerimiz"  ? 'alti-uzun active' : ''} style={{ ...sagdansola }}>Hizmetlerimiz</animated.li>
            </Link>
          </ul>
        </div>
        <div className="logo">
         <Link to='/'><img src={logo} alt="logo" /></Link> 
        </div>
        <div className="listTwo">
          <ul>
            <Link to="/hakkimizda">
              <animated.li className={location.pathname === "/hakkimizda" ? 'alti-uzun active' : ''} style={{ ...soldansaga }}>Hakkımızda</animated.li>
            </Link>
            <Link to="/iletisim">
              <animated.li className={location.pathname === "/iletisim"  ? 'alti-uzun active' : ''} style={{ ...soldansaga }}>İletişim</animated.li>
            </Link>
          </ul>
        </div>
      </div>
    </animated.div>
  );
}

export default Header;
