import React from 'react';
function ServicesInfo() {
    return (
        <div className='ServicesInfo'>
            <img className='ServicesImg' src='https://lirp.cdn-website.com/15c929da/dms3rep/multi/opt/serv2-1920w.jpg' alt='Hakkımızda Fotoğraf' />
            <div className='ServicesInfoDescription'>
                <h3>
                    Hızlı Geri Dönüş
                </h3>
                <p>
                    Biz müşterilerimize değer veriyoruz. Bu nedenle, tüm yazıcı, fotokopi makinesi ve ofis ekipmanı hizmetlerimiz için hızlı geri dönüş süreleri sunmaya özen gösteriyoruz. Hızlı geri dönüş sürelerimiz, mükemmel hizmet ve uygun fiyatlarımızla birleşerek bizi sektörün en iyilerinden biri haline getiriyor.
                </p>
            </div>
        </div>
    )
}

export default ServicesInfo;
