import React from "react";
import Header from "../../components/Header/Header";
import PrintersContent from "../../components/PrintersContent/PrintersContent"
import Footer from "../../components/Footer/Footer";
function Printers() {
  return (
    <>
      <Header />
      <PrintersContent/>
      <Footer />
    </>
  );
}

export default Printers;
