import React from "react";
import Header from "../../components/Header/Header";
import TeknikServisContent from "../../components/TeknikServisContent/TeknikServisContent";
import Footer from "../../components/Footer/Footer";
function TeknikServis() {
  return (
    <>
      <Header />
      <TeknikServisContent/>
      <Footer />
    </>
  );
}

export default TeknikServis;
