import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { Text } from '@chakra-ui/react'
import logo from '../../images/entaofis.png'

function Footer() {
  const location = useLocation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Animasyonlu kaydırma için
    });
    
  };
  const options = [{
    id: 1, title: 'Ana Sayfa', path: '/'
  }, {
    id: 2, title: 'Hakkımızda', path: '/hakkimizda'
  }, {
    id: 3, title: 'Hizmetlerimiz', path: '/hizmetlerimiz'
  },
  { id: 4, title: 'İletişim', path: '/iletisim' }]
  const informations = [{ id: 1, title: 'Telefon: 0532 406 57 77'},
  { id: 2, title: 'E-mail: info@entaofis.com' },
  { id: 3, title: 'Adres: Siyavuşpaşa mh. Çamlık cd. Kaktüs sk. Bahçelievler/İSTANBUL' }]
  return (
    <div className='footer'>
      <div className='footerHead'>
        <div className="footerSections">
          <div className='footerIcon'>
            <img alt='EntaOfis Logo' src={logo} />
            <Text fontWeight='bold' marginTop='5'>EntaOfis</Text>
          </div>
          <div className='footerBrowse'>
            <Text margin='5' fontWeight='bold' fontSize='2xl' >Menü</Text>
            {options.map((option) =>
              <p key={option.id} onClick={scrollToTop}>
                <Text className={location.pathname === option.path ? 'alti-uzun active' : 'alti-uzun'} margin='5'>
                  <NavLink to={option.path}>{option.title}</NavLink>
                </Text>
              </p>

            )}
          </div>
          <div className='footerContactInfo'>
            <Text margin='5' fontWeight='bold' fontSize='2xl' >İletişim Bilgileri</Text>
            {informations.map((info) =>
              <Text key={info.id} margin='5' color='white'>{info.title}</Text>)}
          </div>
        </div>
      </div>
      <div className='footerEnd'>
        Tüm Hakları Saklıdır.
      </div>

    </div>
  )

}



export default Footer