import React from "react";
import Header from "../../components/Header/Header";
import KiralamaContent from "../../components/KiralamaContent/KiralamaContent"
import Footer from "../../components/Footer/Footer";
function Kiralama() {
  return (
    <>
      <Header />
      <KiralamaContent/>
      <Footer />
    </>
  );
}

export default Kiralama;
