//import foto from '../../images/entaofis.png'
import { Text } from "@chakra-ui/react";
//import { useSpring, animated } from "@react-spring/web";
function BoxC() {

  const boxes = [
    { id: 1, title: "Güvenilir Hizmet", photo: "https://cdn-icons-png.flaticon.com/512/10140/10140347.png" },
    { id: 2, title: "Servis Kalitesi", photo: "https://cdn-icons-png.flaticon.com/512/4278/4278812.png" },
    { id: 3, title: "Hızlı", photo: "https://cdn-icons-png.flaticon.com/512/8093/8093007.png" },
    { id: 4, title: "Yılların Deneyimi", photo: "https://cdn-icons-png.flaticon.com/512/10521/10521783.png" },
  ];

  return (
    <div className="boxContainer">
      {boxes.map((box) => (
        <div className="icons" key={boxes.id}>
          <img alt="" src={box.photo} />
          <Text margin="3" fontWeight="bold" fontSize={["xl", "2xl"]} color="black" >{box.title}</Text>
        </div>
      ))}
    </div>
  );
}

export default BoxC;
