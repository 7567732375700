import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactUs from "../../components/ContactUs/ContactUs";
import ContactUsMain from "../../components/ContactUsMain/ContactUsMain"
function Iletisim() {
  return (
    <>
        <Header />
        <ContactUs/>
        <ContactUsMain/>
        <Footer/>
    
    </>
  );
}

export default Iletisim;
