import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useSpring, animated } from "@react-spring/web";
function TeknikServisContent() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Animasyonlu kaydırma için
        });
      };
  const opacity = useSpring({
    from: { opacity: 0, translateY: 30 },
    to: { opacity: 1, translateY: 0 },
  });
  return (
    <div>
    <animated.div style={{ ...opacity }} className="TSAni">
      <div className="TSTitle">
        <h2>
          Büro makinesi arızalarınızın en doğru, güvenilir bir şekilde tespiti ve çözümü için bizi tercih edin.
          EntaOfis'den başka bir yere bakmaya ihtiyacınız yok. Yıllardır profesyonel yazıcı, fotokopi makinesi ve her
          büyüklükteki işletme için ofis ekipmanı hizmetleri sunuyoruz.
          EntaOfis'i seçin, en iyisini seçmiş olursunuz!
        </h2>
      </div>
      
      
    </animated.div>
    <NavLink onClick={scrollToTop} to="/iletisim">
        <Button size="lg" marginTop={["5", "-10"]} marginBottom={["10", "20"]} colorScheme="blue">
          İletişim
        </Button>{" "}
      </NavLink>
  </div>
  );
}

export default TeknikServisContent;
