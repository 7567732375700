import { useSpring, animated } from "@react-spring/web";

function ContactUs() {
    const opacity = useSpring({
        from: { opacity: 0, translateY: 30 },
        to: { opacity: 1, translateY: 0 },
    });

    return (
        <>
            <animated.div style={{ ...opacity }} className="ContactUs">
                <div className="ContactUsContent">
                    <h2>Bizimle İletişime Geçin!</h2>
                </div>
            </animated.div>
        </>

    );
}
export default ContactUs;