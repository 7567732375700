import React from "react";
import { useSpring, animated } from "@react-spring/web";

function FotokopiContent() {
  const opacity = useSpring({
    from: { opacity: 0, translateY: 30 },
    to: { opacity: 1, translateY: 0 },
  });

  const markalar = [
    {
      id: 1,
      marka: "Kyocera",
      modeller: [
        "4020i",
        "3212i",
        "MA4500",
        "MA5500",
        "5003i",
        "M3145dn",
        "2544ci",
        "2554ci",
        "3500ci",
        "MA2100cfx, MA2100cwfx",
        "308ci",
        "M5521cdw",
        "M5526cdn-M5526cdw",
      ],
      aciklama: "",
    },
    {
      id: 2,
      marka: "Develop",
      modeller: ["ineo 250i", "ineo 288", "ineo 300i", "+308"],
      aciklama: "",
    },
    {
      id: 3,
      marka: "Konica Minolta",
      modeller: ["c250i", "c300i", "c257i", "c227i", "c287i"],
      aciklama: "",
    },
  ];

  return (
    <div className="FotokopiContent">
      <animated.div style={{ ...opacity }} className="fotokopiAni">
        <div className="FotokopiTitle">
          <h2>Hizmet Verdiğimiz Fotokopi Makineleri</h2>
        </div>
      </animated.div>
      <div className="markaKutu">
        {markalar.map((marka) => (
          <animated.div
            key={marka.id}
            style={{ ...opacity }}
            className="fotokopiAni"
          >
            <div className="markaContainer">
              <div className="markaTitle">
                <h2>{marka.marka}</h2>
              </div>
              <div className="markaModel">
                <h3>Modeller</h3>
                <ul>
                  {marka.modeller.map((model, idx) => (
                    <li key={idx}>{model}</li>
                  ))}
                </ul>
                {marka.aciklama && <p>{marka.aciklama}</p>}
              </div>
            </div>
          </animated.div>
        ))}
      </div>
    </div>
  );
}

export default FotokopiContent;
