//import { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
function AboutUs() {
    const opacity = useSpring({
        from: { opacity: 0, translateY: 30 },
        to: { opacity: 1, translateY: 0 },
    });

    return (
        <>
            <animated.div style={{ ...opacity }} className="promotion2">
                <div className="promotionContent">
                    <h2>EntaOfis Hakkında</h2>
                    <h3>...</h3>
                    <p>
                        EntaOfis olarak uzun yıllardır İstanbul'da kaliteli ofis/büro makineleri hizmetleri sağlıyoruz. Güvenilir ve kaliteli hizmet için bizi tercih etmeye devam edin!
                    </p>
                   
                </div>
            </animated.div>
        </>

    );
}
export default AboutUs;
