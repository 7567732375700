import React, { useState } from "react";

import { Link } from "react-router-dom";
import { SliderData } from "../../datas/SliderData/SliderData";

function ImageSlider({ slides }) {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
 
  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.targetTouches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchEndX < touchStartX) {
      // Sol kaydırma: Sonraki resim
      nextSlide();
    }
    if (touchEndX > touchStartX) {
      // Sağ kaydırma: Önceki resim
      prevSlide();
    }
  };
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  console.log(current);
  if (!Array.isArray(slides) || slides.length <= 0) return null;

  return (
    <section className="mainSlider"
    >
      {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && (
              <div
              onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
               className="slideImage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-left left-arrow"
                  width={40}
                  height={40}
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  onClick={prevSlide}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <polyline points="15 6 9 12 15 18"></polyline>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-right right-arrow"
                  width={40}
                  height={40}
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  onClick={nextSlide}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <polyline points="9 6 15 12 9 18"></polyline>
                </svg>

                <Link to="/">
                  <img src={slide.image} alt="" className="image" />
                </Link>
              </div>
            )}
          </div>
        );
      })}
    </section>
  );
}

export default ImageSlider;
