import React from "react";
import Header from "../../components/Header/Header";
import AboutUs from '../../components/AboutUs/AboutUs.js';
import Footer from "../../components/Footer/Footer";
import AboutUsInfo from '../../components/AboutUsInfo/AboutUsInfo.js'
function Hakkimizda() {
  return (
    <>
      <Header />
      <AboutUs />
      <AboutUsInfo />
      <Footer />


    </>
  );
}

export default Hakkimizda;
