import React from 'react'
import { useSpring, animated } from "@react-spring/web";
function ServicesTitle() {
    const opacity = useSpring({
        from: { opacity: 0, translateY: 30 },
        to: { opacity: 1, translateY: 0 },
    });
    return (
        <>
            <animated.div style={{ ...opacity }} className="Services">
                <div className="ServicesTitle">
                    <h2>Yazıcılar, Fotokopi ve Büro Makineleri</h2>
                    <h3>...</h3>
                    <p>
                    EntaOfis olarak, kaliteli büro makinesi ve bakımının önemini gerçekten anlıyoruz. Ofis cihazlarının onarımını veya servisini beklemek, değerli zaman kayıplarına neden olabilir. Bu olası sorunu hafifletmek için müşteri memnuniyeti sağlayan şirkete ihtiyacınız var. Sorunsuz bir şekilde hizmetinizi sürdürmek için makinelerinizin ve malzemelerinizin bakımının hayati önem taşıdığını biliyoruz.
                    </p>

                </div>
            </animated.div>
        </>
    )
}

export default ServicesTitle