import React from 'react'
import {Link} from 'react-router-dom'
import Header from '../../components/Header/Header'
function Bakımda() {
  return (
    <>
     <Header />
      <div className='bakimSayfasi'>
            Bu sayfa şuan bakımda, <Link className='bakimIletisim' to="/iletisim"><p>iletişim</p></Link> sayfasından bizimle iletişime geçebilirsiniz.
       </div>
    </>
  )
}

export default Bakımda