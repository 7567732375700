import React from "react";
import Slider from "../../components/Slider/Slider.js";
import Header from "../../components/Header/Header.js";
import Box from "../../components/Box/Box.js";
import Promotion from "../../components/Promotion/Promotion.js";
import { Text } from "@chakra-ui/react";
import OurServices from "../../components/OurServices/OurServices.js";
import Footer from '../../components/Footer/Footer.js'
function Content() {
  return (
    <div className="Content">
      <Header />
      <Slider />
      <Promotion />
      <div className="titleBox">
        <Text marginTop="1" marginBottom="10" fontSize={["5xl", "6xl"]}>
          Neden Biz?
        </Text>
      </div>
      <Box />
      <div className="titleBox">
        <Text marginTop="10" marginBottom="10" fontSize={["5xl", "6xl"]}>
          Hizmetlerimiz
        </Text>
      </div>
      <OurServices/>
      <footer><Footer className="FooterGeneral"/></footer>
      
      
    </div>
  );
}

export default Content;
