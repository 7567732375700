import ImageSlider from "../ImageSlider/ImageSlider";

import { SliderData } from "../../datas/SliderData/SliderData";
function Slider() {
  
  return (
    <div className="Slider">
      <ImageSlider slides={SliderData} />
    </div>
  );
}

export default Slider;
