import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { NavLink } from "react-router-dom";
import { Button } from "@chakra-ui/react";
function TonerContent() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Animasyonlu kaydırma için
    });
  };
  const opacity = useSpring({
    from: { opacity: 0, translateY: 30 },
    to: { opacity: 1, translateY: 0 },
  });
  const markalar = [
    {
      id: 1,
      aciklama: "Neden satın almak yerine kiralamayı tercih etmelisiniz ?",
      aciklama2:
        "Fotokopi makinesi kiralama hizmeti ile yatırım maliyetinizi minimuma indirirken bakım maliyetlerini de tamamen hayatınızdan çıkartabilirsiniz!",
      aciklama3:
        "Siyah beyaz ya da renkli fotokopi makinesi/yazıcı kiralama hizmetinden faydalanan kurumların iş akışlarını hızlandırıyor ve maliyetlerini ciddi oranda azaltıyoruz. Her ölçekten, her sektörden işletmenin ofis işlemlerini daha verimli hale getiriyoruz.",
      aciklama4: "İşte satın almak yerine kiralamanın size sağladığı bazı avantajlar:",
      aciklama5: "Tüm bu hizmetlerden faydalanmak için şimdi bizimle iletişime geçin!",
      maddeler: [
        "Yüksek maliyetlerinden tasarruf",
        "Toner, drum, developer vb. sarf malzeme maliyetlerinden tasarruf",
        "Servis ve bakım hizmeti",
        "Ücretsiz kurulum ve yer değiştirme hizmeti",
      ],
    },
  ];

  return (
    <div>
      <animated.div style={{ ...opacity }} className="kiralamaAni">
        <div className="kiralamaTitle">
          <h2>Fotokopi Makinesi / Yazıcı Kiralama</h2>
        </div>
      </animated.div>
      <div className="kiralamaKutu">
        {markalar.map((marka) => (
          <animated.div
            key={marka.id}
            style={{ ...opacity }}
            className="kiralamaAni"
          >
            <div className="kiralamaContainer">
              <div className="kiralamaModel">
                {marka.aciklama && <p>{marka.aciklama}</p>}
              </div>{" "}
            </div>
            <div className="kiralamaContainer2">
              {" "}
              <div className="kiralamaModel2">{marka.aciklama2 && <p>{marka.aciklama2}</p>}</div>
            </div>
            <div  className="kiralamaContainer3">
              {" "}
              <div className="kiralamaModel3">{marka.aciklama3 && <p>{marka.aciklama3}</p>}</div>
            </div>

            <div className="kiralamaContainer4">
              <div className="kiralamaModel4">{marka.aciklama4 && <p>{marka.aciklama4}</p>}</div>
            </div>
            <div className="kiralamaContainer5">
                <div className="kiralamaModel5"> <ul>
                {marka.maddeler.map((madde, idx) => (
                  <li key={idx}>{madde}</li>
                ))}
              </ul></div>
             
            </div>
            <div className="kiralamaContainer">
              <div className="kiralamaModel">
                {marka.aciklama5 && <p>{marka.aciklama5}</p>}
              </div>{" "}
            </div>
          </animated.div>
        ))}
      </div>
      <NavLink onClick={scrollToTop} to="/iletisim">
        <Button size="lg" marginTop={["5", "-10"]} marginBottom={["5", "20"]} colorScheme="blue">
          İletişim
        </Button>{" "}
      </NavLink>
    </div>
  );
}

export default TonerContent;
