import React from "react";
import Header from "../../components/Header/Header";
import TonerContent from "../../components/TonerContent/TonerContent"
import Footer from "../../components/Footer/Footer";
function Toner() {
  return (
    <div className="Toner">
      <Header />
      <TonerContent/>
      <Footer />
    </div>
  );
}

export default Toner;
