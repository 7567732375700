import "./App.css";
import "./reset.css";
import Bakımda from './pages/Bakımda/Bakımda'
import Hizmetlerimiz from "./pages/Hizmetlerimiz/Hizmetlerimiz";
import Iletisim from "./pages/Iletisim/Iletisim";
import Content from "./pages/Content/Content";
import Fotokopi from "./pages/Fotokopi/Fotokopi";
import Printers from "./pages/Printers/Printers";
import Toner from "./pages/Toner/Toner";
import Kiralama from "./pages/Kiralama/Kiralama";
import TeknikServis from "./pages/TeknikServis/TeknikServis";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Hakkimizda from "./pages/Hakkimizda/Hakkimizda";
function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      //admine bilgi ver
    }
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/hizmetlerimiz" element={<Hizmetlerimiz />} />
        <Route path="/iletisim" element={<Iletisim />} />
        <Route path="/hakkimizda" element={<Hakkimizda />} />
        <Route path="/maintenance" element={<Bakımda />} />
        <Route path="/printers" element={<Printers />} />
        <Route path="/fotokopi" element={<Fotokopi />} />
        <Route path="/toner" element={<Toner/>} />
        <Route path="/kiralama" element={<Kiralama />} />
        <Route path="/teknik-servis" element={<TeknikServis />} />
        <Route />
        <Route />
      </Routes>
    </div>
  );
}

export default App;
