import slide1 from "../../images/slide1.png"
import slide2 from "../../images/slide2.png"
import slide3 from "../../images/slide3.png"
import slide4 from "../../images/slide4.png"
import slide5 from "../../images/slide5.png"

export const SliderData = [
    {
      image:
      slide1,
    },
    {
      image:
        slide2,
    },
    {
      image:
        slide3,
    },
    {
      image:
        slide4,
    },
    {
      image:
        slide5,
    },
  ];
  