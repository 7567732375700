import { Text , Spinner } from '@chakra-ui/react'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import logo from '../../images/entaofis.png'
function ContactUsMain() {
 // const serviceId=process.env.REACT_APP_SERVICE_ID;
 // const templateId=process.env.REACT_APP_TEMPLATE_ID;
 // const publicId=process.env.REACT_APP_PUBLIC_ID;

  const form = useRef();
  const[loading, setLoading] = useState(false); // loading state
  const [success, setSuccess] = useState(false); // success state
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); // Loading başladığında aktif hale getir

    emailjs
      .sendForm('service_5kth0cr', 'template_u1fly5r', form.current, {
        publicKey:"bREp6EPzKFrXW5A5h",
      })
      .then(
        () => {
          setLoading(false); // Loading'i durdur
          setSuccess(true); // Başarı durumunu aktif hale getir
          alert("Mesajınız Başarıyla Alındı, En kısa sürede mail üzerinden geri dönüş yapacağız daha fazla bilgi almak için bizi arayabilirsiniz! ");
          console.log('SUCCESS!');
        },
        (error) => {
          setLoading(false); // Loading'i durdur
          setSuccess(false); // Başarısızlık durumunda success false kalır
          alert("Mesaj Alınamadı, Lütfen Tekrar Deneyin!");
          console.log('FAILED...', error.text);
        },
      );
  };
  const informations = [{ id: 1, title: 'Telefon: 0532 406 57 77' },
  { id: 2, title: 'E-mail: info@entaofis.com' },
  { id: 3, title: 'Adres: Siyavuşpaşa mh. Çamlık cd. Kaktüs sk. Bahçelievler/İSTANBUL' }]
  return (
    <div className="ContactUsMain">
      <div className="ContactUsInfo">
        <div className='ContactUsIcon'>
          <img alt='EntaOfis Logo' src={logo} />
          <Text fontWeight='bold' marginTop='5'>EntaOfis</Text>
        </div>
        {informations.map((info) =>
          <Text className="ContactUsInfos" key={info.id} margin='5' >{info.title}</Text>)}
      </div>
      <div className="ContactUsUser">
      <form ref={form} onSubmit={sendEmail}>
        <div  className="contactUsForm"><label>Ad Soyad</label>
      <input className="usernameInput" type="text" name="user_name" /></div>
      <div  className="contactUsForm"><label>E-mail</label>
      <input  className="emailInput" type="email" name="user_email" /></div>
      <div className="lblMesaj"><label>Mesaj</label>
      <textarea id="messageInput" name="message" />
     
      </div>
      <div style={{ position: 'relative',width: '120px',
                height: '50px',
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '50px'
                }}>
            <input
              className="ContactUsButton"
              type="submit"
              value="Gönder"
              disabled={loading} // Yükleniyorsa buton devre dışı kalır
              
            />
            {loading && (
              <Spinner
              
               size="lg" // Daha büyük boyut,
                 
                  color="blue"
                  thickness="4px" // Kalınlık artırıldı
                  speed="0.65s"
                  emptyColor="gray.200"
                  style={{
                    boxSize: '20px', // Spinner boyutu ayarlandı
                    marginTop: "0px",
                  }}
              />
            )}
          </div>

          {success && (
            <div style={{ marginTop: '10px', color: 'green', fontWeight: 'bold' }}>
              ✅ Mesaj Başarıyla Gönderildi!
            </div>
          )}
      
    </form>
       
      </div>
    </div>
  );
}
export default ContactUsMain;

