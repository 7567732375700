import React from "react";
import { useSpring, animated } from "@react-spring/web";
function PrintersContent() {
  const opacity = useSpring({
    from: { opacity: 0, translateY: 30 },
    to: { opacity: 1, translateY: 0 },
  });
  const markalar = [
   
    {
      id: 1,
      marka: "Canon",
      modeller: ["lbp6030", "lbp223dw", "lbp226dw", "mf463", "mf553", "mf552", "mf657"],
      aciklama: "",
    },
    {
      id: 2,
      marka: "HP",
      modeller: ["150nw", "179fnw", "107w", "137fnw","4103fdn", "m304a", "4003n"],
      aciklama: "",
    },
    {
      id: 3,
      marka: "Epson",
      modeller: ["L3250", "L3251", "L3252", "L3260", "L3550"],
      aciklama: "",
    },
  ];

  return (
    <div className="printersContent">
    <animated.div style={{ ...opacity }} className="printerAni">
      <div className="printerTitle">
        <h2>Hizmet Verdiğimiz Yazıcılar</h2>
      </div>
    </animated.div>
    <div className="markaKutu">
      {markalar.map((marka) => (
        <animated.div key={marka.id} style={{ ...opacity }} className="printerAni">
          <div className="markaContainer">
            <div className="markaTitle">
              <h2>{marka.marka}</h2>
            </div>
            <div className="markaModel">
              <h3>Modeller</h3>
              <ul>
                {marka.modeller.map((model, idx) => (
                  <li key={idx}>{model}</li>
                ))}
              </ul>
              {marka.aciklama && <p>{marka.aciklama}</p>}
            </div>
          </div>
        </animated.div>
      ))}
    </div>
  </div>
  );
}

export default PrintersContent;
