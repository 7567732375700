
import React from 'react';
import {Text} from '@chakra-ui/react'
import { NavLink} from 'react-router-dom'
import { useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
function ServicesDetail() {
    const subtitles = [{url:"/printers" , title: "Yazıcı", img: 'https://www.ankarakartustoner.com/wp-content/uploads/hp-m-1212-yaz%C4%B1c%C4%B1-tamiri.jpg', alt: 'Yazıcı' },
    {url:"/fotokopi" ,  title: "Fotokopi Makinesi", img: 'https://static.ticimax.cloud/31687/uploads/urunresimleri/buyuk/canon-renkli-fotokopi-makinesicanon-i--49-4f0.jpg', alt: 'Fotokopi Makinesi' },
    {url:"/toner", title: "Toner", img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVjkbnx83FW6Hpi5C08DkAWuCsd5QSTO13_Q&usqp=CAU', alt: 'Toner' },
    {url:"/kiralama", title: "Kiralama", img: 'https://blog.ofix.com/wp-content/uploads/2021/06/yazici_temizligi_ofix_blog.jpg', alt: 'Bakım' },
    {url:"/teknik-servis",  title: "Teknik Servis", img: 'https://www.antalyakartustoner.net/files/yazici-teknik-servis-ve-bakimi_463_1522410520.jpg', alt: 'Teknik Servis' }];
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Animasyonlu kaydırma için
        });
        
      };
  
  // Ref to handle the scroll container
  const scrollContainer = useRef(null);

  // Function to handle left scroll
  const scrollLeft = () => {
    scrollContainer.current.scrollBy({
      left: -300, // Adjust scroll distance
      behavior: "smooth",
    });
  };

  // Function to handle right scroll
  const scrollRight = () => {
    scrollContainer.current.scrollBy({
      left: 300, // Adjust scroll distance
      behavior: "smooth",
    });
  };


    return (

        <div className='ServicesDetail'>
            <div className='ServicesCon'>
            <h2>
                    Yazıcı ve Fotokopi Makinesi Hizmetlerimiz
                </h2>
            <div className="scrollableContainer">
            {/* Left Arrow */}
            <button
              onClick={scrollLeft}
              className="scrollLeftButton"
            >
              <ChevronLeftIcon w={10} h={10} />
            </button>

            {/* Scrollable Container */}
            <ul
              ref={scrollContainer}
              className="scrollList"
            >
              {subtitles.map((subtitle, index) => (
                <li key={index} style={{ flex: "0 0 auto", margin: "0 15px" }}>
                  <NavLink onClick={scrollToTop} to={subtitle.url}>
                    <img
                      src={subtitle.img}
                      alt={subtitle.alt}
                      style={{}}
                    />
                    <Text fontSize={["xl", "3xl"]} fontWeight="bold" color="black">
                      {subtitle.title}
                    </Text>
                  </NavLink>
                </li>
              ))}
            </ul>

            {/* Right Arrow */}
            <button
              onClick={scrollRight}
              className="scrollRightButton"
            >
              <ChevronRightIcon w={10} h={10} />
            </button>
          </div>
                   


            </div>
            {
                 <Text  marginBottom="20" fontSize="xl" fontWeight="bold" color="black">Bilgi Almak İstediğiniz Kategorinin Üstüne Tıklayabilirsiniz</Text> 
            }
        </div>


    )
}

export default ServicesDetail